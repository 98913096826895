import React, { Fragment, useEffect, useState } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useMutation } from '@apollo/client';
import { SEND_REDEEM_INVITATION } from './graphql/mutation';
import { Form, Input, Button, Select, notification } from 'antd';
import logo from './assets/logo.svg';

const { Option } = Select;

const operationSystem = [
    { label: 'iOS', value: 'IOS' },
    { label: 'ANDROID', value: 'ANDROID' },
];

export const InviteForm = () => {
    const [note, setNote] = useState(false);
    const [form] = Form.useForm();
    const [sendRedeemInvitation] = useMutation(SEND_REDEEM_INVITATION, {});

    const handleSubmit = (values) => {
        try {
            sendRedeemInvitation({
                variables: {
                    data: {
                        user_code: values?.user_code,
                        category: 'AGENT',
                        device_type: values?.device_type,
                    }
                }
            }).then((res) => {
                notification['success']({
                    message: "Yêu cầu thành công",
                    description: "Link tải ứng dựng đã được gởi vào mail cá nhân của ban. Hãy kiểm tra email để tải và cài đặt ứng dụng."
                });
                setNote(true);
            }).catch(error => {
                notification['error']({
                    message: "Thất bại",
                    description: error?.message || 'Đăng ký thái thất bại'
                })
            })
        } catch (error) {
            notification['error']({
                message: "Thất bại",
                description: error?.message || 'Đăng ký thái thất bại'
            })
        }
    }

    const onFinish = (values) => {
        const user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha) === true) {
            handleSubmit(values);
            loadCaptchaEnginge(6);
            document.getElementById('user_captcha_input').value = "";
        }
        else {
            notification['warning']({ message: "Mã xác nhận không đúng." });
            document.getElementById('user_captcha_input').value = "";
        }
    };

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    return (
        <Fragment>
            
            <div className='app'>
                <div className='appLogo' style={{}}>
                    <img src={logo}  alt="logo" />
                </div>
                <div className='wrapper-form'>
                <header className='appHeader'>
                    <p className='title'>Đăng ký sử dụng</p>
                    <p className='title'>ứng dụng dành cho nhân viên</p>
                </header>

                {
                    note && (
                        <div className='success-note'>
                            {/* <p>Link tải ứng dựng đã được gởi vào mail cá nhân của ban.</p>
                            <p>Hãy kiểm tra email để tải ứng và cài đặt ứng dụng.</p> */}
                            <i>(Vui lòng kiểm tra mục Spam, nếu không thấy trong Inbox)</i>
                        </div>
                    )
                }

                <Form
                    name="invite_form"
                    layout="vertical"
                    className='ant-form'
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="user_code"
                        label="Mã nhân viên"
                        rules={[{ required: true, message: 'Thông tin bắt buộc!' }]}
                    >
                        <Input autoComplete='off' placeholder="Nhập mã nhân viên" />
                    </Form.Item>

                    <Form.Item
                        name="device_type"
                        label='Nền tảng thiết bị'
                        rules={[{ required: true, message: 'Thông tin bắt buộc!' }]}
                    >
                        <Select placeholder='Chọn iOS/Android'>
                            {
                                operationSystem.map( item => <Option key={item?.value} value={item?.value}>{item?.label}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item className='wapper-captcha' label='Mã xác thực'>
                        <LoadCanvasTemplate reloadText='Chọn mã khác' reloadColor="#01837b" />
                    </Form.Item>

                    <Form.Item name="user_captcha_input">
                        <Input 
                            id="user_captcha_input"
                            autoComplete='off'
                            placeholder="Nhập mã xác thực"
                            type="text"
                        />
                    </Form.Item>

                    <div className='btn-register'>
                        <Button  type="primary" htmlType="submit">Đăng ký</Button>
                    </div>
                </Form>
                </div>
            </div>
        </Fragment>
    )
}
