import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/config";
import { InviteForm } from "./InviteForm";

function App() {

  return (
    <ApolloProvider client={client}>
      <InviteForm />
    </ApolloProvider>
  );
}

export default App;
